import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";
import { FETCH_PATIENTS_REQUEST } from "../create-order";
import { showConfirmDialog } from "../confirm-dialog";

const USERS_API = process.env.REACT_APP_PHARMACY_USERS_API;
const API_URL = process.env.REACT_APP_API_URL;

// ACTIONS
export const SELECT_USER_TO_MATCH = "MATCH_USERS/SELECT_USER_TO_MATCH";

export const FETCH_AUTOCOMPLETE_USER_REQUEST =
  "MATCH_USERS/FETCH_AUTOCOMPLETE_USER_REQUEST";
export const FETCH_AUTOCOMPLETE_USER_RECEIVE =
  "MATCH_USERS/FETCH_AUTOCOMPLETE_USER_RECEIVE";
export const FETCH_AUTOCOMPLETE_USER_FAILURE =
  "MATCH_USERS/FETCH_AUTOCOMPLETE_USER_FAILURE";

export const MATCH_USER_REQUEST = "MATCH_USERS/MATCH_USER_REQUEST";
export const MATCH_USER_RECEIVE = "MATCH_USERS/MATCH_USER_RECEIVE";
export const MATCH_USER_FAILURE = "MATCH_USERS/MATCH_USER_FAILURE";

export const GET_UNMATCHED_SERVICE_REQUEST =
  "MATCH_USERS/GET_UNMATCHED_SERVICE_REQUEST";
export const GET_UNMATCHED_SERVICE_RECEIVE =
  "MATCH_USERS/GET_UNMATCHED_SERVICE_RECEIVE";
export const GET_UNMATCHED_SERVICE_FAILURE =
  "MATCH_USERS/GET_UNMATCHED_SERVICE_FAILURE";
export const GET_UNMATCHED_SERVICE_PAGED_REQUEST =
  "MATCH_USERS/GET_UNMATCHED_SERVICE_PAGED_REQUEST";
export const GET_UNMATCHED_SERVICE_PAGED_RECEIVE =
  "MATCH_USERS/GET_UNMATCHED_SERVICE_PAGED_RECEIVE";
export const GET_UNMATCHED_SERVICE_PAGED_FAILURE =
  "MATCH_USERS/GET_UNMATCHED_SERVICE_PAGED_FAILURE";

export const SELECT_MATCHED_USER = "MATCH_USERS/SELECT_MATCHED_USER";

export const CLEAR_USER_SUGGESTIONS = "MATCH_USERS/CLEAR_USER_SUGGESTIONS";
export const CLEAR_SELECTED_USER = "MATCH_USERS/CLEAR_SELECTED_USER";
export const CLEAR_MATCHED_USER = "MATCH_USERS/CLEAR_MATCHED_USER";

// ACTION CREATORS
export const selectUser = user => ({
  type: SELECT_USER_TO_MATCH,
  payload: { user }
});
export const clearSelectedUser = () => ({
  type: CLEAR_SELECTED_USER
});

export const confirmServiceMatch = (pmrUser, pharmacyUser, selectedService) => {
  if (selectedService === "Care-Home")
    return showConfirmDialog({
      title: "Are you sure?",
      message:
        "Prescriptions for patients assigned to the wrong service may be delayed or may not reach the patient at all. Please ensure you are assigning this patient correctly. This action cannot be undone.",
      positiveActionTitle: "ASSIGN PATIENT TO CARE HOMES",
      agreeActionCreator: () => matchUser(pmrUser, undefined, selectedService)
    });
  return showConfirmDialog({
    title: "Confirm user match",
    message:
      `This will match PMR patient ${pmrUser.firstName} ${pmrUser.lastName} with PharmOs user ` +
      `${pharmacyUser.firstName} ${pharmacyUser.lastName}.` +
      " This action cannot be undone.",
    positiveActionTitle: "Confirm",
    agreeActionCreator: () =>
      matchUser(pmrUser, pharmacyUser?.userId, selectedService)
  });
};

export const matchUser = (
  pmrUser,
  userId,
  service,
  uniqueId = Date.now(),
  apiEndpoint = API_URL
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/pmr-interface-service/patient/${pmrUser.nhsNumber}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userId,
        service
      }),
      types: [
        MATCH_USER_REQUEST,
        MATCH_USER_RECEIVE,
        MATCH_USER_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

export const getUnmatchedUsers = (
  uniqueId = Date.now(),
  apiEndpoint = API_URL
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/pmr-interface-service/patient`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        GET_UNMATCHED_SERVICE_REQUEST,
        GET_UNMATCHED_SERVICE_RECEIVE,
        GET_UNMATCHED_SERVICE_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

export const getAdditionalUnmatchedUsers = (
  nextToken,
  uniqueId = Date.now(),
  apiEndpoint = USERS_API
) => {
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/pmr-interface-service/patient?startKey=${nextToken}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        GET_UNMATCHED_SERVICE_PAGED_REQUEST,
        GET_UNMATCHED_SERVICE_PAGED_RECEIVE,
        GET_UNMATCHED_SERVICE_PAGED_FAILURE
      ].map(type => ({ type, meta: { uniqueId } })),
      options: { addAuth: true }
    }
  };
};

export const changeUserSearch = (
  text,
  uniqueId = Date.now(),
  apiEndpoint = USERS_API
) => {
  if (text.length < 2)
    return { type: FETCH_PATIENTS_REQUEST, meta: { uniqueId, text } };
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/users/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name: text }),
      types: [
        FETCH_AUTOCOMPLETE_USER_REQUEST,
        FETCH_AUTOCOMPLETE_USER_RECEIVE,
        FETCH_AUTOCOMPLETE_USER_FAILURE
      ].map(type => ({ type, meta: { uniqueId, text } })),
      options: { addAuth: true }
    }
  };
};

export const selectUserSuggestion = user => ({
  type: SELECT_MATCHED_USER,
  payload: { user }
});

export const clearUserSuggestions = () => ({
  type: CLEAR_USER_SUGGESTIONS
});

export const clearMatchedUser = () => ({
  type: CLEAR_MATCHED_USER
});

// SELECTORS
export const getAllUnmatchedUsers = state => state.unmatchedUsers;
export const getSelectedUser = state => state.selectedUser;
export const getSelectedMatchedUser = state => state.selectedMatchUser;
export const getUserSuggestions = state => state.userAutocomplete.suggestions;
export const getUserSearchText = state => state.userSearchText;
export const getNeedsUpdate = state => state.needsRefresh;
export const getFetching = state => state.fetching;
export const getFetchingAdditional = state => state.fetchingAdditional;
export const getMatching = state => state.matching;
export const getFirstPageFetched = state => state.firstPageFetched;
export const getAllPagesFetched = state =>
  state.firstPageFetched && state.nextToken === null;
export const getNextToken = state => state.nextToken;

// REDUCERS
const unmatchedUsers = handleActions(
  {
    [GET_UNMATCHED_SERVICE_RECEIVE]: (state, action) =>
      action.payload.data?.patients ?? [],
    [GET_UNMATCHED_SERVICE_PAGED_RECEIVE]: (state, action) =>
      state.concat(action.payload.data.patients)
  },
  []
);

const selectedUser = handleActions(
  {
    [SELECT_USER_TO_MATCH]: (state, action) => action.payload.user,
    [GET_UNMATCHED_SERVICE_RECEIVE]: () => null,
    [MATCH_USER_RECEIVE]: () => null,
    [CLEAR_SELECTED_USER]: () => null
  },
  null
);

const selectedMatchUser = handleActions(
  {
    [SELECT_MATCHED_USER]: (state, action) => action.payload.user,
    [CLEAR_MATCHED_USER]: () => null,
    [MATCH_USER_RECEIVE]: () => null
  },
  null
);

const userSearchText = handleActions(
  {
    [CLEAR_MATCHED_USER]: () => "",
    [MATCH_USER_RECEIVE]: () => "",
    [FETCH_AUTOCOMPLETE_USER_REQUEST]: (state, action) => action.meta.text || ""
  },
  ""
);

const userAutocomplete = handleActions(
  {
    [SELECT_MATCHED_USER]: () => ({ reqId: null, suggestions: [] }),
    [CLEAR_USER_SUGGESTIONS]: () => ({ reqId: null, suggestions: [] }),
    [MATCH_USER_RECEIVE]: () => ({ reqId: null, suggestions: [] }),
    [FETCH_AUTOCOMPLETE_USER_REQUEST]: (state, action) => ({
      ...state,
      reqId: action.meta.uniqueId
    }),
    [FETCH_AUTOCOMPLETE_USER_RECEIVE]: (state, action) => {
      if (action.meta.uniqueId === state.reqId) {
        return {
          suggestions: action.payload.map(p => ({
            ...p,
            address: `${p.addressLine1}, ${p.postcode}`
          })),
          reqId: null
        };
      }
      return state;
    },
    [FETCH_AUTOCOMPLETE_USER_FAILURE]: (state, action) =>
      action.meta.uniqueId === state.reqId ? { ...state, reqId: null } : state
  },
  { reqId: null, suggestions: [] }
);

const needsRefresh = handleActions(
  {
    [MATCH_USER_RECEIVE]: () => true,
    [GET_UNMATCHED_SERVICE_RECEIVE]: () => false
  },
  false
);

const fetching = handleActions(
  {
    [GET_UNMATCHED_SERVICE_REQUEST]: () => true,
    [GET_UNMATCHED_SERVICE_RECEIVE]: () => false,
    [GET_UNMATCHED_SERVICE_FAILURE]: () => false
  },
  false
);

const fetchingAdditional = handleActions(
  {
    [GET_UNMATCHED_SERVICE_PAGED_REQUEST]: () => true,
    [GET_UNMATCHED_SERVICE_PAGED_RECEIVE]: () => false,
    [GET_UNMATCHED_SERVICE_PAGED_FAILURE]: () => false
  },
  false
);

const matching = handleActions(
  {
    [MATCH_USER_REQUEST]: () => true,
    [MATCH_USER_RECEIVE]: () => false,
    [MATCH_USER_FAILURE]: () => false
  },
  false
);

const nextToken = handleActions(
  {
    [GET_UNMATCHED_SERVICE_RECEIVE]: (state, action) =>
      action.payload.next ? action.payload.next : null,
    [GET_UNMATCHED_SERVICE_PAGED_RECEIVE]: (state, action) =>
      action.payload.next ? action.payload.next : null
  },
  null
);

const firstPageFetched = handleActions(
  {
    [GET_UNMATCHED_SERVICE_RECEIVE]: state => state || true
  },
  false
);

export default combineReducers({
  unmatchedUsers,
  selectedUser,
  selectedMatchUser,
  userSearchText,
  userAutocomplete,
  needsRefresh,
  fetching,
  fetchingAdditional,
  matching,
  nextToken,
  firstPageFetched
});
