import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import s from "./style.module.scss";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Card from "@material-ui/core/Card";
import { formatDateString } from "../../helpers/date-helpers";
import {
  getUserMatchIsMatching,
  getUserMatchIsLoading,
  getUserMatchNeedsUpdate,
  getUserMatchSearchText,
  getUserMatchSelectedMatchedUser,
  getUserMatchSelectedUser,
  getUserMatchSuggestions,
  getUserMatchUnmatchedUsers,
  getUserMatchAllPagesFetched,
  getUserMatchNextToken,
  getUserMatchIsLoadingAdditional
} from "../../redux/root-reducer";
import {
  changeUserSearch,
  clearMatchedUser,
  clearSelectedUser,
  clearUserSuggestions,
  confirmUserMatch,
  getUnmatchedUsers,
  selectUser,
  selectUserSuggestion,
  confirmUnmatchedUserDelete,
  getAdditionalUnmatchedUsers
} from "../../redux/match-users";
import Grid from "@material-ui/core/Grid";
import AutoComplete from "../common/autocomplete";
import Button from "@material-ui/core/Button";
import CardList from "../common/card-list";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfiniteScroll from "../common/infinite-scroll";

const pmrUserShape = PropTypes.shape({
  nhsNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired
});
const pharmacyUserShape = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired
});

export const formatUserDescription = user =>
  `${formatDateString(user.dob)}, ${user.addressLine1}, ${user.postcode}`;

export function MatchUsersStepper({ activeStep }) {
  const steps = ["Select unknown user", "Find and match user"];
  return (
    <Stepper
      classes={{ root: s.userStepperRoot }}
      activeStep={activeStep}
      alternativeLabel
    >
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

MatchUsersStepper.propTypes = {
  activeStep: PropTypes.number
};

MatchUsersStepper.defaultProps = {
  activeStep: 1
};

export class MatchPanel extends React.Component {
  componentDidUpdate(prevProps) {
    const { selectedUser, clearMatchedUser } = this.props;

    const prevSelectedUser = prevProps.selectedUser;
    if (prevSelectedUser != null && prevSelectedUser !== selectedUser) {
      clearMatchedUser();
    }
  }

  render() {
    const {
      userSuggestions,
      selectedUser,
      selectedMatchUser,
      userSearchText,
      onUserSuggestionTextChange,
      onCloseUserSuggestions,
      onUserSuggestionSelect,
      onMatchClick,
      matching,
      onDeleteClick
    } = this.props;

    const suggestions = userSuggestions.map(u => ({
      name: `${u.firstName} ${u.lastName}`,
      description: formatUserDescription(u)
    }));

    const userDescription = selectedMatchUser ? (
      formatUserDescription(selectedMatchUser)
    ) : (
      <>&nbsp;</>
    );

    return selectedUser ? (
      <div>
        <Card className={s.matchPanel}>
          <AutoComplete
            id={"m_autocomplete_user"}
            placeholder={"Search by user name"}
            text={userSearchText}
            inputVariant={"outlined"}
            onTextChange={t => onUserSuggestionTextChange(t)}
            onOuterClick={onCloseUserSuggestions}
            onSuggestionSelect={index =>
              onUserSuggestionSelect(userSuggestions[index])
            }
            suggestions={suggestions}
          />

          <p>{userDescription}</p>

          <Button
            fullWidth
            variant={"contained"}
            color={"primary"}
            className={s.matchButton}
            disabled={!selectedMatchUser || matching}
            onClick={() => onMatchClick(selectedMatchUser)}
          >
            Match
          </Button>
          <Button
            fullWidth
            variant={"contained"}
            color={"primary"}
            className={`${s.deleteButton} delete-unmatched-user`}
            disabled={false}
            onClick={() => onDeleteClick(selectedMatchUser)}
          >
            Delete User
          </Button>
        </Card>
      </div>
    ) : null;
  }
}

MatchPanel.propTypes = {
  selectedUser: pmrUserShape,
  selectedMatchUser: pharmacyUserShape,
  userSuggestions: PropTypes.arrayOf(pharmacyUserShape).isRequired,
  userSearchText: PropTypes.string,
  onUserSuggestionTextChange: PropTypes.func.isRequired,
  onCloseUserSuggestions: PropTypes.func.isRequired,
  onUserSuggestionSelect: PropTypes.func.isRequired,
  clearMatchedUser: PropTypes.func.isRequired,
  onMatchClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

MatchPanel.defaultProps = {
  selectedUser: undefined,
  selectedMatchUser: undefined,
  userSearchText: undefined
};

export class MatchUsers extends Component {
  renderUserCard = user => {
    return (
      <div className={s.userCardDetails}>
        <p className={"name"}>{user.name}</p>
        <p>{formatUserDescription(user)}</p>
      </div>
    );
  };

  componentDidMount() {
    this.props.getUnmatchedUsers();
  }

  componentDidUpdate(prevProps) {
    const { needsUpdate, getUnmatchedUsers } = this.props;

    if (!prevProps.needsUpdate && needsUpdate) {
      getUnmatchedUsers();
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedUser();
  }

  render() {
    const {
      isLoading,
      users,
      selectedUser,
      selectUser,
      selectedMatchUser,
      userSuggestions,
      userSearchText,
      onUserSuggestionTextChange,
      onCloseUserSuggestions,
      onUserSuggestionSelect,
      clearMatchedUser,
      matching,
      onMatchClick,
      onDeleteClick,
      allPagesFetched,
      getAdditionalUnmatchedUsers,
      nextToken,
      isLoadingAdditional
    } = this.props;

    if (isLoading) {
      return (
        <div className={s.root}>
          <div className={s.progress}>
            <CircularProgress id={"t_progress"} size={50} />
          </div>
        </div>
      );
    }

    if (users === undefined || users.length === 0) {
      return (
        <div id={"#m_noData"} className={s.noData}>
          <h2>There are currently no unmatched users.</h2>
        </div>
      );
    }

    const userCards = users.map(user => ({
      id: user.nhsNumber,
      data: user,
      selected: selectedUser && selectedUser.nhsNumber === user.nhsNumber
    }));

    return (
      <div className={s.pageWrapper}>
        <MatchUsersStepper activeStep={selectedUser ? 1 : 0} />

        <div>
          <Grid container spacing={32} classes={{ container: s.pageGrid }}>
            <Grid item xs={6}>
              <InfiniteScroll
                onReachThreshold={
                  !allPagesFetched
                    ? () => getAdditionalUnmatchedUsers(nextToken)
                    : () => {}
                }
                isLoading={isLoadingAdditional}
              >
                <CardList
                  selectorType={"radio"}
                  cards={userCards}
                  onCardSelectionChanged={selectUser}
                  render={this.renderUserCard}
                />
              </InfiniteScroll>
            </Grid>
            <Grid item xs={6}>
              <MatchPanel
                selectedUser={selectedUser}
                selectedMatchUser={selectedMatchUser}
                userSuggestions={userSuggestions}
                userSearchText={userSearchText}
                onUserSuggestionTextChange={onUserSuggestionTextChange}
                onCloseUserSuggestions={onCloseUserSuggestions}
                onUserSuggestionSelect={onUserSuggestionSelect}
                clearMatchedUser={clearMatchedUser}
                matching={matching}
                onMatchClick={onMatchClick}
                onDeleteClick={onDeleteClick}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

MatchUsers.propTypes = {
  selectedUser: pmrUserShape,
  selectedMatchUser: pharmacyUserShape,
  selectUser: PropTypes.func.isRequired,
  needsUpdate: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingAdditional: PropTypes.bool,
  matching: PropTypes.bool,
  allPagesFetched: PropTypes.bool,
  nextToken: PropTypes.string,

  users: PropTypes.arrayOf(pmrUserShape),
  userSearchText: PropTypes.string,
  userSuggestions: PropTypes.arrayOf(pharmacyUserShape).isRequired,
  getUnmatchedUsers: PropTypes.func.isRequired,
  getAdditionalUnmatchedUsers: PropTypes.func.isRequired,
  onUserSuggestionTextChange: PropTypes.func.isRequired,
  onCloseUserSuggestions: PropTypes.func.isRequired,
  onUserSuggestionSelect: PropTypes.func.isRequired,
  clearMatchedUser: PropTypes.func.isRequired,
  onMatchClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  clearSelectedUser: PropTypes.func.isRequired
};

MatchUsers.defaultProps = {
  selectedUser: undefined,
  selectedMatchUser: undefined,
  userSearchText: undefined,
  users: undefined,
  needsUpdate: false,
  isLoading: false,
  isLoadingAdditional: false,
  matching: false,
  allPagesFetched: false,
  nextToken: undefined
};

export const mapDispatchToProps = {
  getUnmatchedUsers,
  getAdditionalUnmatchedUsers,
  selectUser,
  clearMatchedUser,
  onUserSuggestionTextChange: changeUserSearch,
  onCloseUserSuggestions: clearUserSuggestions,
  onUserSuggestionSelect: selectUserSuggestion,
  onMatchClick: confirmUserMatch,
  onDeleteClick: confirmUnmatchedUserDelete,
  clearSelectedUser
};

export const mapStateToProps = state => ({
  selectedUser: getUserMatchSelectedUser(state),
  selectedMatchUser: getUserMatchSelectedMatchedUser(state),
  userSuggestions: getUserMatchSuggestions(state),
  userSearchText: getUserMatchSearchText(state),
  users: getUserMatchUnmatchedUsers(state),
  needsUpdate: getUserMatchNeedsUpdate(state),
  isLoading: getUserMatchIsLoading(state),
  isLoadingAdditional: getUserMatchIsLoadingAdditional(state),
  matching: getUserMatchIsMatching(state),
  allPagesFetched: getUserMatchAllPagesFetched(state),
  nextToken: getUserMatchNextToken(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onMatchClick: () =>
    dispatchProps.onMatchClick(
      stateProps.selectedUser,
      stateProps.selectedMatchUser
    ),
  onDeleteClick: () => dispatchProps.onDeleteClick(stateProps.selectedUser)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MatchUsers);
